// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
$primary-color: #55090A;
$secondary-color: #A3A410;
$secondary-color2: #ED3424;
$secondary-color3: #f69f1a;
.display-inline {
    display: inline;
}

body {
    background-color: white;
}

.navbar {
    margin-bottom: 0;
}

.navbar-default {
    border: none !important;
    outline: none !important;
    background-color: white;
}

.navbar-default .navbar-nav > li > a {
    color: black;
    font-weight: bold;
}

.navbar-default .navbar-brand {
    display: none;
}

.nav-container {
    background-color: transparent;
}

.welcome-col {
    margin-bottom: 1em;
}

.panel-default > .panel-heading {
    display: none;
    color: white;
    background-color: white;
    border-color: black;
}

.panel {
    border-color: black;
    margin-top: 2em;
    border: none;
    outline: none;
}

.panel-body {
    padding: 0;
}

.panel-welcome {
    border: 1px solid black;
    outline: none;
}

.btn-equipment-lrg {
    width: 100%;
}

.btn-submit-form {
    margin-top: 1em;
    margin-bottom: 1em;
}

.btn-equipment-sml {
    width: 50%;
}

.equipment-action-col {
    width: 12.1em;
    border: none !important;
    outline: none !important;
}

.border-kill {
    border: none !important;
    outline: none !important;
}

.equipment-action-row {
    padding: 0 !important;
    vertical-align: middle !important;
}

.footer-bar-background {
    position: relative;
    width: 100%;
    height: 375px;
}

#app-layout {
    max-width: 100vw;
    background-position: bottom;
    background-image: url("/images/mine2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.title-div {
    color: white;
}


.sso-login {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    background: #fff;


    .panel {
        background: none;
        box-shadow: none;
    }

    .col-md-8 {
        padding-left: 0;
    }
}

@media (max-width: 990px) {
    .sso-login {
        width: 90%;

        .col-md-8 {
            padding-left: 15px;
        }
    }
}

.container.login,
.container.new,
.container.permit,
.container.register,
.container.show {
    background: #fff;
    padding: 0 35px;
}

.footer-bar {
    height: 375px;
}

.title-text {
    margin-top: 100px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

.createnew-search {
    float: left !important;
}

.submit-search {
    float: right !important;
}

.equipment-show-header {
    padding-left: 13px;

}

.equipment-show:nth-child(even) {
    background: #E1E7EB;
}

.btn {
    background-color: #E1E7EB;
    color: #2C2E2F;
    border: none;
}

.btn:hover {
    background-color: #d2dbe1;
    color: #2C2E2F;
}

.btn-main {
    background-color: $secondary-color3;
}

.btn-main:hover {
    background-color: #fbd193;
}

@media (max-width: 990px) {
    .btn-equipment-lrg {
        width: 20%;
        float: right;
    }
}

.btn-wide {
    width: 100%;
}

.btn-back {
    margin-bottom: 1em;
}

h1 {
    font-size: 48px;
}

h2 {
    font-weight: 200;
}

@media (min-width: 992px) {
    .col-md-offset-3 {
        margin-left: 30%;
    }
}

@media (min-width: 992px) {
    .col-md-offset-3 {
        margin-left: 30%;
    }
}

.createnew-search a {
    margin: 0 10px 10px 0;
}

.permit-search {
    width: 90%;
    float: left;
    margin: 10px 10px 10px 0;
}

.permit-submit {
    float: left;
    width: 8%;
    margin: 10px 10px 10px 0;
}
